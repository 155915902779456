<template>
  <div class="charge">
    <div class="introduction">
      <h1>捐赠页面</h1>
      <ul>
        <li>
          感谢您能来到soutudashi.com的捐赠界面，您的支持是我们继续前行的不竭动力！
        </li>
      </ul>
      <h1>我们的使命：</h1>
      <ul>
        <li><span>通过AI的力量，让创意图片生成变得触手可及</span></li>
        <li>
          <span>为专业人士和爱好者提供高效的工业级工具，助力创新和生产力</span>
        </li>
      </ul>
      <h1>您的支持意味着：</h1>
      <ul>
        <li><span>帮助我们不断优化和扩展AI图片库，满足更多创意需求。</span></li>
        <li>
          <span>使我们可以持续研发和改进工业工具，支持您的项目和工作。</span>
        </li>
      </ul>
    </div>

    <a href="javascript:void(0)" @click="click_donation_btn">立即捐赠</a>
    <ul class="charge_ul" v-if="show_donation">
      <li class="charge_li" v-for="(item, index) in data_list" :key="index">
        <div
          class="goods_item"
          :class="{ selected: index === selectedSponsorItem.id }"
          @click="click_item(index, item)"
        >
          <img class="recharge_pic" :src="item.pic_url" alt="" />
          <span>{{ item.name }}</span>
          <span>￥ {{ item.price }}</span>
          <span style="text-align: left; padding: 0px 10px">{{
            item.moral
          }}</span>
        </div>
      </li>
    </ul>

    <div class="pay_form" v-if="show_donation">
      <el-row>
        <span class="pay-price"
          >￥ {{ cur_price }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
        >
        <el-button type="primary" @click="submit_pay">支付</el-button>
      </el-row>
    </div>
  </div>
</template>

<script>
import { get_token } from "../common/common.js";
export default {
  data() {
    return {
      data_list: [],
      selectedSponsorItem: {
        id: 0,
        price: this.cur_price,
      },
      show_donation: false,
    };
  },
  methods: {
    async get_recharge_data() {
      try {
        var response = await this.$http.get(
          "http://www.soutudashi.com/recharge",
          {
            headers: { "Content-Type": "application/json" },
          }
        );

        // console.log("获取赞助数据：4 ", response.data);
        this.data_list = response.data;
        return response.data;
        // }
      } catch (e) {
        console.log(e);
      } finally {
        // this.comment_content = "";
      }
    },

    click_item(index, item) {
      // console.log("点了元素： ", index);
      this.selectedSponsorItem.id = index;
    },

    click_donation_btn() {
      this.show_donation = !this.show_donation;
    },
    async submit_pay() {
      try {
        const token = get_token("token");
        const data = {
          name: this.goods_name,
          num: 1,
          price: this.cur_price,
          integral: 0,
          type: "赞助",
        };
        // var response = await this.$http.post(
        //   "http://www.soutudashi.com/send_pay_request",
        //   {
        //     headers: {
        //       "Content-Type": "application/json",
        //       Authorization: `Bearer ${token}`,
        //     },
        //     data,
        //   }
        // );

        var response = await this.$http({
          method: "post",
          url: "http://www.soutudashi.com/send_pay_request", // 拼接URL和参数
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*", // 设置跨域参数
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            Authorization: `Bearer ${token}`,
          },
          data: data,
          responseType: "json",
        });

        // console.log("获取赞助数据：3 ", response.data);
        const url = response.data.data.url;
        // this.$router.push(url);
        window.open(url);
        // }
      } catch (e) {
        console.log(e);
      } finally {
        // this.comment_content = "";
      }
    },
  },
  mounted() {
    this.get_recharge_data();
  },

  computed: {
    cur_price() {
      return this.data_list[this.selectedSponsorItem.id]["price"];
    },
    goods_name() {
      return this.data_list[this.selectedSponsorItem.id]["name"];
    },
  },
};
</script>

<style scoped>
.selected {
  /* background-color: blue; */
  box-sizing: content-box;
  border: 10px blue solid !important;
}
.charge_ul {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  /* padding: 20px 50px; */
  width: 800px;
  margin: 0px auto;
  list-style-type: none;
  text-decoration: none;
}
.recharge_pic {
  width: 200px;
  height: 200px;
  margin: 10px 0px;
}

.goods_item {
  width: 200px;
  margin: 20px;
  border: 1px solid grey;
}
.goods_item {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.pay-price {
  font-size: 25px;
  font-weight: bold;
}

.charge > .introduction {
  width: 800px;
  margin: 0px auto;
  text-align: left;
}
.charge > .introduction > ul > li {
  list-style-type: disc;
  /* text-align: left; */
}
</style>
